import React from "react";
import { array1, array2, navItem1, navItem2 } from "./pages/MebookLandingCrousel/Data.js";
import MeCircularProgress from "./components/componentsC/meCircularProgress/MeCircularProgress.jsx";
import LandingPage from "./pages/landingPage/LandingPage.jsx";
import { Box } from "@mui/material";
import NewAskAI from "./components/AI/AskAI/NewAskAI.jsx";
import Whistlist from "./components/whistlist/Whistlist.jsx";
import Landing from "./pages/landingPage/landing.jsx";
const ForgotPassword = React.lazy(() => import("./Component new/ForgotPassword.jsx"));
const ProfileDetails = React.lazy(() => import("./component/ProfileDetails"));
const ConfirmMail = React.lazy(() => import("./component/ConfirmMail"));
const AuthorProfile = React.lazy(() => import("./component/ProfileAuthor"));
const TelevisionProfile = React.lazy(() => import("./component/ProfileTelevision"));
const CreatorWriter = React.lazy(() => import("./component/CreatorWriter"));
const CreatorMusic = React.lazy(() => import("./component/CreatorMusic"));
const CreatorVisualGraphic = React.lazy(() => import("./component/CreatorVisualGraphics"));
const MainStage = React.lazy(() => import("./UnusedFile/MainStage.jsx"));
const CuttingEdge = React.lazy(() => import("./UnusedFile/CuttingEdge"));
const AtLarge = React.lazy(() => import("./UnusedFile/AtLarge"));
const Identity = React.lazy(() => import("./component/Identity"));
const Private = React.lazy(() => import("./component/Private"));
const CreatorNew = React.lazy(() => import("./component/CreatorNew"));
const CreatorNew1 = React.lazy(() => import("./component/CreatorNew1"));
const New = React.lazy(() => import("./Component new/New"));
const Signup = React.lazy(() => import("./Component new/Signup"));
const UserInfo = React.lazy(() => import("./Component new/User Dashboard/UserInfo"));
const SignIn = React.lazy(() => import("./Component new/SignIn"));
const NewSignInPage = React.lazy(() => import("./Component new/NewSigninPage/NewSignInPage"));
const UserDashboard = React.lazy(() => import("./Component new/User Dashboard/UserDashboard"));
const DetailSaved = React.lazy(() => import("./Component new/DetailSaved"));
const CompletionPhase = React.lazy(() => import("./Component new/CompletionPhase"));
const CompletionIdentity = React.lazy(() => import("./Component new/CompletionIdentity"));
const ProfileSubscription = React.lazy(() => import("./Component new/ProfileSubscription"));
const PaymentPage = React.lazy(() => import("./Component new/PaymentPage"));
const UserProfile = React.lazy(() => import("./Component new/User Dashboard/UserProfile"));
const Crousel = React.lazy(() => import("./Component new/CrouselPage"));
const UserChoice = React.lazy(() => import("./Component new/UserChoice"));
const MultipleOptions = React.lazy(() => import("./UnusedFile/MultipleOptions"));
const WritePitch = React.lazy(() => import("./Component new/WritePitch"));
const WriterStoryDetails = React.lazy(() => import("./Component new/WriterStoryDetails"));
const WriterPublishPage = React.lazy(() => import("./Component new/WriterPublishPage"));
const WriterNav = React.lazy(() => import("./Component new/WriterNav"));
const WriterStory = React.lazy(() => import("./Component new/WriterStory"));
const BenefitsOfMebook = React.lazy(() => import("./Component new/BenefitsOfMebook"));
const Educator = React.lazy(() => import("./Component new/Reader/Educator"));
const ProjectDeveloper = React.lazy(() => import("./Component new/Reader/ProjectDeverloper"));
const ReaderReviewer = React.lazy(() => import("./Component new/Reader/ReaderReviewer"));
const RetailStore = React.lazy(() => import("./Component new/Reader/RetailStore"));
const Buyer = React.lazy(() => import("./Component new/Reader/Buyer"));
const ViewPublishedStory = React.lazy(() => import("./Component new/ViewPublishedStory"));
const UpdatePublishedStory = React.lazy(() => import("./Component new/UpdatePublishedStory"));
const CheckOtp = React.lazy(() => import("./Component new/CheckOtp"));
const ResetPassword = React.lazy(() => import("./Component new/ResetPassword"));
const PaymentHtml = React.lazy(() => import("./Component new/PaymentHtml"));
const CreatorTheatrical = React.lazy(() => import("./component/CreatorTheatrical"));
const PaymentDetails = React.lazy(() => import("./Component new/paymentPage/PaymentDetails"));
const BankSelect = React.lazy(() => import("./Component new/paymentPage/BankSelect"));
const UserProfileEdit = React.lazy(() => import("./Component new/User Dashboard/UserProfileEdit"));
const EmailConfirm = React.lazy(() => import("./EmaiConfirm/EmailConfirm"));
const MainDashboard = React.lazy(() => import("./userDashboard/MainDashboard"));
const DashboardMain = React.lazy(() => import("./Component new/User Dashboard/DashboardMain/DashboardMain"));
const ErrorPage = React.lazy(() => import("./component/ErrorPage"));
const MyWork = React.lazy(() => import("./Component new/userWork/MyWork"));
const Faqs = React.lazy(() => import("./dashboardComponent/Faqs"));
const WorkView = React.lazy(() => import("./Component new/userWork/WorkView"));
const DashboardCard = React.lazy(() => import("./Component new/User Dashboard/userDashboardItem/DashboardCard"));
const WorkViewTop5 = React.lazy(() => import("./Component new/userWork/WorkViewTop5"));
const Feedback = React.lazy(() => import("./Component new/feedBack/Feedback"));
const AllWork = React.lazy(() => import("./Component new/userWork/AllWork"));
const Copyrights = React.lazy(() => import("./Component new/Copyrights"));
const PitchInfo = React.lazy(() => import("./Component new/User Dashboard/Pitch/PitchInfo"));
const PrivateCross = React.lazy(() => import("./component/PrivateCross"));
const AboutMe = React.lazy(() => import("./pages/aboutMe/AboutMe"));
const NewPayPage = React.lazy(() => import("./Component new/paymentPage/NewPayPage"));
const ProfileContainer = React.lazy(() => import("./component/navSearch/ProfileContainer"));
const MyAsk = React.lazy(() => import("./pages/myAsk/MyAsk"));
const Opportunities = React.lazy(() => import("./pages/Opportunities/Opportunities"));
const NewSigUpPage = React.lazy(() => import("./Component new/NewSignUpPage/NewSignUpPage"));
const SharePage = React.lazy(() => import("./Component new/SharePage/SharePage.jsx"));
const TermsNC = React.lazy(() => import("./pages/PrivacyPolicy/TermsNC"));
const VideoCarousel = React.lazy(() => import("./Component new/VideoCrousel/VideoCrousel"));
const PaymentSuccess = React.lazy(() => import("./Component new/PaymentSuccess/PaymentSuccess"));
const MeProfilePage = React.lazy(() => import("./pages/ProfilePageUi/MeProfilePage"));
const Messenger1 = React.lazy(() => import("./component/NewChat/Messenger1"));
const Privacy = React.lazy(() => import("./pages/PrivacyPolicy/Privacy"));
const Research = React.lazy(() => import("./pages/research/Research"));
const PostHome = React.lazy(() => import("./components/PostSection/PostHome/PostHome.jsx"));
const AI = React.lazy(() => import("./components/AI/AI"));
const MebookLandingCrousel = React.lazy(() => import("./pages/MebookLandingCrousel/MebookLandingCrousel.jsx"));
const PitchAlley = React.lazy(() => import("./Component new/PitchCrousel/PitchAlley.jsx"));
const MyAskCrosel = React.lazy(() => import("./Component new/MyAskCrosel/MyAskCrosel.jsx"));
const SinglePost = React.lazy(() => import("./components/PostSection/SinglePost/SinglePost.jsx"));


const suspense = (component) => {
  return <React.Suspense fallback={<Box>
    {/* <CircularProgress sx={circularProgressbarStyle} /> */}
    <MeCircularProgress />
  </Box>}>{component}</React.Suspense>
}




const oppenedRoute = [
 

  {
    path: '/',
    component: <Landing />,
    index: true,
  },
  {
    path: 'New',
    component: suspense(<New />),
    index: false,
  },
  {
    path: 'BenefitsOfMebook',
    component: suspense(<BenefitsOfMebook />),
    index: false,
  },
  {
    path: 'NewSignUpPage',
    component: <Signup />,
    index: false,
  },
  {
    path: 'NewSigninPage',
    component: <SignIn />,
    index: false,
  },
  {
    path: 'SignIn',
    component: suspense(<NewSignInPage />),
    index: false,
  },
  {
    path: 'ForgotPassword',
    component: suspense(<ForgotPassword />),
    index: false,
  },
  {
    path: 'Signup',
    component: suspense(<NewSigUpPage />),
    index: false,
  }
]
const authenticatedRoute = [
  {
    path: 'ProfileDetails',
    component: suspense(<ProfileDetails />),
    index: false,
  },

  {
    path: 'PaymentSuccess',
    component: suspense(<PaymentSuccess />),
    index: false,
  },
  {
    path: 'CreatorWriter',
    component: suspense(<CreatorWriter />),
    index: false,
  },
  {
    path: 'CreatorMusic',
    component: suspense(<CreatorMusic />),
    index: false,
  },
  {
    path: 'CreatorVisualGraphic',
    component: suspense(<CreatorVisualGraphic />),
    index: false,
  },
  {
    path: 'CuttingEdge',
    component: suspense(<CuttingEdge />),
    index: false,
  },
  {
    path: 'AtLarge',
    component: suspense(<AtLarge />),
    index: false,
  },
  {
    path: 'MainStage',
    component: suspense(<MainStage />),
    index: false,
  },
  {
    path: 'Identity',
    component: suspense(<Identity />),
    index: false,
  },
  {
    path: 'ConfirmMail',
    component: suspense(<ConfirmMail />),
    index: false,
  },
  {
    path: 'CreatorNew1/subcategory/:category',
    component: suspense(<AuthorProfile />),
    index: false,
  },
  {
    path: 'TelevisionProfile',
    component: suspense(<TelevisionProfile />),
    index: false,
  },
  {
    path: 'CreatorNew/:position',
    component: suspense(<CreatorNew />),
    index: false,
  },
  {
    path: 'CreatorNew1',
    component: suspense(<CreatorNew1 />),
    index: false,
  },
  {
    path: 'CompletionPhase',
    component: suspense(<CompletionPhase />),
    index: false,
  },
  {
    path: 'NewPayPage',
    component: suspense(<NewPayPage />),
    index: false,
  },
  {
    path: 'VideoCarousel',
    component: suspense(<VideoCarousel />),
    index: false,
  },
  {
    path: 'UserInfo',
    component: suspense(<UserInfo />),
    index: false,
  },
  // {
  //   path: 'ProfileDetails',
  //   component: suspense(<ProfileDetails />),
  //   index: false,
  // },
  {
    path: 'UserDashboard/:username',
    component: suspense(<UserDashboard />),
    index: false,
  },
  {
    path: 'DetailSaved',
    component: suspense(<DetailSaved />),
    index: false,
  },
  {
    path: 'CompletionIdentity',
    component: suspense(<CompletionIdentity />),
    index: false,
  },
  {
    path: 'ProfileSubscription',
    component: suspense(<ProfileSubscription />),
    index: false,
  },
  {
    path: 'PaymentPage',
    component: suspense(<PaymentPage />),
    index: false,
  },
  {
    path: 'PaymentPage/BankSelect',
    component: suspense(<BankSelect />),
    index: false,
  },
  {
    path: 'PaymentPage/PaymentDetails',
    component: suspense(<PaymentDetails />),
    index: false,
  },
  {
    path: 'UserProfile/:username',
    component: suspense(<UserProfile />),
    index: false,
  },
  {
    path: 'Crousel',
    component: suspense(<Crousel />),
    index: false,
  },
  {
    path: 'UserChoice',
    component: suspense(<UserChoice />),
    index: false,
  },
  {
    path: 'MultipleOptions',
    component: suspense(<MultipleOptions />),
    index: false,
  },
  {
    path: 'WritePitch',
    component: suspense(<WritePitch />),
    index: false,
  },
  {
    path: 'WriterPublishPage',
    component: suspense(<WriterPublishPage />),
    index: false,
  },
  {
    path: 'WriterNav',
    component: suspense(<WriterNav />),
    index: false,
  },
  {
    path: 'WriterStory',
    component: suspense(<WriterStory />),
    index: false,
  },
  {
    path: 'Buyer',
    component: suspense(<Buyer />),
    index: false,
  },
  {
    path: 'RetailStore',
    component: suspense(<RetailStore />),
    index: false,
  },
  {
    path: 'ReaderReviewer',
    component: suspense(<ReaderReviewer />),
    index: false,
  },
  {
    path: 'ProjectDeveloper',
    component: suspense(<ProjectDeveloper />),
    index: false,
  },
  {
    path: 'Educator',
    component: suspense(<Educator />),
    index: false,
  },
  {
    path: 'PaymentHtml',
    component: suspense(<PaymentHtml />),
    index: false,
  },
  {
    path: 'UpdatePublishedStory',
    component: suspense(<UpdatePublishedStory />),
    index: false,
  },
  {
    path: 'ViewPublishedStory',
    component: suspense(<ViewPublishedStory />),
    index: false,
  },
  {
    path: 'Private',
    component: suspense(<Private />),
    index: false,
  },
  {
    path: 'CreatorTheatrical',
    component: suspense(<CreatorTheatrical />),
    index: false,
  },
  {
    path: 'UserProfileEdit',
    component: suspense(<UserProfileEdit />),
    index: false,
  },
  {
    path: 'WorkView/:id/:name',
    component: suspense(<WorkView />),
    index: false,
  },
]
const mainDashhboard = [
  {
    path: '/',
    component: suspense(<DashboardMain />),
    index: true,
  },
  // {
  //   path: 'NewPayPage',
  //   component: suspense(<LandingPage />),
  //   index: false,
  // },
  {
    path: 'MyPitch',
    component: suspense(<WritePitch />),
    index: false,
  },
  {
    path: 'myask-universe',
    component: suspense(<MyAskCrosel />),
    index: false,
  },
  {
    path: 'pitchAlley',
    component: suspense(<PitchAlley />),
    index: false,
  },
  {
    path: 'MyPitch/PitchInfo',
    component: suspense(<PitchInfo />),
    index: false,
  },
  {
    path: 'MyAsk',
    component: suspense(<MyAsk />),
    index: false,
  },
  {
    path: 'opportunities/:name/:userId',
    component: suspense(<Opportunities />),
    index: false,
  },
  {
    path: 'WriterStoryDetails',
    component: suspense(<MyWork />),
    index: false,
  },
  {
    path: 'createStory/:process',
    component: suspense(<WriterStoryDetails />),
    index: false,
  },
  {
    path: 'Copyrights',
    component: suspense(<Copyrights />),
    index: false,
  },
  {
    path: 'Chat',
    component: suspense(<Messenger1 />),
    index: false,
  },
  {
    path: 'UserProfile/:username/:userId',
    component: suspense(<MeProfilePage />),
    index: false,
  },
  {
    path: 'WriterStoryDetails/:userId',
    component: suspense(<MyWork />),
    index: false,
  },
  {
    path: 'WorkView/:userId/:id/',
    component: suspense(<WorkView />),
    index: false,
  },
  {
    path: 'WorkViewTop5/:id',
    component: suspense(<WorkViewTop5 />),
    index: false,
  },
  {
    path: 'feedback',
    component: suspense(<Feedback />),
    index: false,
  },
  {
    path: 'post/:userId',
    component: suspense(<PostHome />),
    index: false,
  },
  {
    path: 'Search/:query/:category',
    component: suspense(<ProfileContainer />),
    index: false,
  },
  {
    path: 'UserProfile/:username/:userId/CreatorNew2/:active',
    component: suspense(<CreatorNew1 />),
    index: false,
  },
  {
    path: 'AboutMe/:username/:userId/CreatorNew2/:active',
    component: suspense(<CreatorNew1 />),
    index: false,
  },
  {
    path: 'Faqs',
    component: suspense(<Faqs />),
    index: false,
  },
  {
    path: 'AboutMe',
    component: suspense(<AboutMe />),
    index: false,
  },
  {
    path: 'AllWork/:userId',
    component: suspense(<AllWork />),
    index: false,
  },
  {
    path: "singlepost/:postId",
    component: suspense(<SinglePost />),
    index: false,
  },
  {
    path: 'chat-with-me',
    component: suspense(<AI />),
    index: false,
  },
  {
    path: 'meai',
    component: suspense(<NewAskAI />),
    index: false,
  },
  {
    path: 'wishlist',
    component: suspense(<Whistlist />),
    index: false,
  },

]
export const routeObject = [
  {
    path: '/',
    component: suspense(<PrivateCross />),
    index: false,
    children: oppenedRoute
  },
  {
    path: '/',
    component: suspense(<Private />),
    index: false,
    children: authenticatedRoute
  },
  {
    path: '/MainDashboard',
    component: suspense(<MainDashboard />),
    index: false,
    children: mainDashhboard
  },
  {
    path: '/Profile/:username/:userId',
    component: suspense(<MeProfilePage />),
    index: false,
    children: []
  },
  {
    path: '/EmailConfirm/:id',
    component: suspense(<EmailConfirm />),
    index: false,
    children: []
  },
  {
    path: '/ConfirmMail/:email',
    component: suspense(<ConfirmMail />),
    index: false,
    children: []
  },
  {
    path: '/ResetPassword',
    component: suspense(<ResetPassword />),
    index: false,
    children: []
  },
  {
    path: '/CheckOtp',
    component: suspense(<CheckOtp />),
    index: false,
    children: []
  },
  {
    path: '/singlepost/:postId',
    component: suspense(<SinglePost />),
    index: false,
    children: []
  },
  {
    path: '/share-page/:uniqueId/:userId',
    component: suspense(<SharePage />),
    index: false,
    children: []
  },
  {
    path: '/opportunities/:name/:userId',
    component: suspense(<Opportunities />),
    index: false,
    children: []
  },
  {
    path: '*',
    component: suspense(<ErrorPage />),
    index: false,
    children: []
  },
  {
    path: 'ErrorPage',
    component: suspense(<ErrorPage />),
    index: false,
    children: []
  },
  {
    path: 'DashboardCard',
    component: suspense(<DashboardCard />),
    index: false,
    children: []
  },
  {
    path: 'WorkView/:userId/:id/',
    component: suspense(<WorkView />),
    index: false,
    children: []
  },
  {
    path: 'WriterStoryDetails/:userId',
    component: suspense(<MyWork />),
    index: false,
    children: []
  },
  {
    path: 'WriterStoryDetails',
    component: suspense(<MyWork />),
    index: false,
    children: []
  },
  {
    path: 'Search/:query/:category',
    component: suspense(<ProfileContainer />),
    index: false,
    children: []
  },
  {
    path: 'tnc',
    component: suspense(<TermsNC />),
    index: false,
    children: []
  },
  {
    path: 'privacypolicy',
    component: suspense(<Privacy />),
    index: false,
    children: []
  },
  {
    path: 'research',
    component: suspense(<Research />),
    index: false,
    children: []
  },
  {
    path: 'post/:userId',
    component: suspense(<PostHome />),
    index: false,
    children: []
  },
  {
    path: 'identities',
    component: suspense(<MebookLandingCrousel navItem={navItem1} researchData={array1} />),
    index: false,
    children: []
  },
  {
    path: 'creatives',
    component: suspense(<MebookLandingCrousel navItem={navItem2} researchData={array2} />),
    index: false,
    children: []
  },
]