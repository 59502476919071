import PaddFooter from "../../PaddFooter"
import LandingNavNew from "./components/landingNavNew/LandingNavNew"
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import { CiDollar } from "react-icons/ci";
import { GoSponsorTiers } from "react-icons/go";
import { FaCreativeCommonsBy } from "react-icons/fa";
import { MdOutlineStart } from "react-icons/md";
import Zoom from "./components/zoom";
import Introvideo from "./components/introvideo";
import ChatGpt from "./components/chatGpt";
import Slider from "./components/slider/slider";
import { S3_VIDEO_URL, array1, array2 } from './asset';
import PreviewAsk from "./components/previewAsk/previewAsk";
import Testimonial from "./components/tetstimonnial/testimonial";
import TrendingWorks from "./components/trendingWorks/trendingWorks";
import LandingNavbar from "./components/landingNavbar/landingNavbar";
import welcome from "../../components/voiceMessage/WelcomeVoice.mp3";
import useSound from "use-sound";
import { useDispatch } from 'react-redux';
import { callPopUp } from "../../Action";
import { useEffect, useRef, useState } from "react";
import MePic from '../../mepic.png';
import { useNavigate } from 'react-router-dom';
import ThemeToggler from "./components/themeToggler/ThemeToggler";
import { useTheme } from "../../contextApi/themeContext";
import { Modal } from "@mui/material";


const Loader = () => {
    return (
        <div className="d-flex text-white align-items-center justify-content-center flex-column" style={{ minHeight: '100vh' }}>
            <img src={MePic} alt="image" width="300" height={300} />
            <div>
                <marquee behavior="scroll" direction="left">
                    <h2>The Global Media Marketplace</h2>
                </marquee>
            </div>
        </div>
    );
};



const Landing = () => {
    const [loading, setLoading] = useState(() => {
        if (sessionStorage.getItem('landing_count') < 1) {
            return true
        } else {
            return false
        }
    })
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [play, { stop }] = useSound(welcome);
    const { theme } = useTheme();
    const [isPlaying, setIsPlaying] = useState(false); // Track video playback state
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDonate = () => {
        dispatch(callPopUp({ type: 'DonationForm' }))
        // dispatch(callPopUp({ type: 'SuccessDonation' }))
    }

    const handleSignUp = (query) => {
        localStorage.setItem('userType', JSON.stringify(query))
        navigate('/Signup')
    }

    const handleSponsor = () => {
        dispatch(callPopUp({ type: "SponsershipForm" }))
    }


    useEffect(() => {
        const clr = setTimeout(() => {
            setLoading(false)
            sessionStorage.setItem('landing_count', JSON.stringify(1))
        }, 5800)
        return () => clearTimeout(clr)
    }, [])



    useEffect(() => {
        if (window?.location?.search?.includes('?success=true')) {
            dispatch(callPopUp({ type: 'SuccessDonation' }))
        }
        if (window?.location?.search?.includes('?sponsor=') && !window?.location?.search?.includes('?sponsor=false')) {
            dispatch(callPopUp({ type: 'SuccessSponsor' }))
        }

    }, [])


    if (loading) {
        return <Loader />
    } else {
        return (
            <>
                <LandingNavNew className="z-10" />
                {/* <LandingNavbar className="z-10" /> */}
                <div className="z-50 fixed bottom-20 right-2">
                    <ThemeToggler />

                </div>
                <div className="w-full p-8  flex flex-col space-y-4 " style={{ backgroundColor: theme === 'dark' ? 'black' : 'white' }}>
                <div className="flex lg:flex-row flex-col items-center justify-between w-full space-y-8 lg:space-y-0">
                    {/* First Div with Text */}
                    <div className="lg:p-8 p-0 flex lg:flex-row flex-col items-center justify-around w-full space-y-8 lg:space-y-0">
                        <div>
                            <h3 className="text-start text-black text-4xl font-bold tracking-widest" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                                        MeBookMeta
                            </h3>
                            <p className="text-start tracking-widest text-gray-600" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                                <span className="font-bold text-lg">It's All About You</span><br />
                                Share Your Work – Build Your Following<br />
                                Explore Art, in Every Form, Everywhere
                            </p>
                        </div>
                        <div className="w-32 lg:p-8 p-0 flex justify-center">
                            <img
                                src="/me-removebg-preview.png"
                                alt="Rotating Image"
                                className="w-32 h-32 rounded-full border border-gray-400 hover:border-blue-800 shadow-lg cursor-pointer animate-spin-slow"
                                onClick={handleOpen} // Open modal on click
                            />
                        </div>
                    </div>

                    {/* Rotating Image */}
                    

                    {/* Video Div */}
                    <div className="w-[90%] lg:p-8 p-0">
                        <video
                            className="border rounded-xl mb-4"
                            poster='/mepic.png'
                            src={`${S3_VIDEO_URL}/1688492047308-Mebookmeta+video.mp4`}
                            width="100%"
                            autoPlay
                            muted
                            controls
                            loop
                            controlsList="nodownload"
                        ></video>
                    </div>
                    {/* MUI Modal */}
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        className="flex items-center justify-center"
                        >
                        <div className="p-6 outline-none">
                            <div className="flex justify-center space-x-4">
                            <div
                                onClick={() => handleSignUp('Creative')}
                                className="px-4 p-2 flex items-center justify-center text-white bg-gradient-to-r from-blue-800 to-gray-800 duration-300 cursor-pointer hover:rounded-md"
                            >
                                Sign-Up As a Creative <FaCreativeCommonsBy className="ml-2" fontSize="24px" />
                            </div>
                            <div
                                onClick={() => handleSignUp('Visitor')}
                                className="px-4 p-2 flex items-center justify-center text-white bg-gradient-to-r from-blue-800 to-gray-800 duration-300 cursor-pointer hover:rounded-md"
                            >
                                Sign-Up As a Visitor <MdOutlineStart className="ml-2" fontSize="24px" />
                            </div>
                            </div>
                        </div>
                    </Modal>

                    </div>
                    
                    <div className="w-full">
                        <Zoom />
                    </div>
                    <div className="w-full">
                        <Introvideo />
                    </div>
                    <div className="w-full">
                        <ChatGpt />
                    </div>
                    <div className="w-full flex flex-col space-y-4">
                        <h1 className="text-center text-3xl text-black font-bold tracking-widest mt-4" style={{ color: theme === 'dark' ? 'white' : 'black' }}>MeBookMeta Audiences</h1>
                        <Slider slide={array1} />
                    </div>

                    <div className="w-full">
                        <PreviewAsk />
                    </div>
                    <div className="w-full flex flex-col space-y-4">
                        <h1 className="text-center text-3xl text-black font-bold tracking-widest mt-4" style={{ color: theme === 'dark' ? 'white' : 'black' }}>MeBookMeta Creatives</h1>
                        <Slider slide={array2} />
                    </div>
                    <div className="w-full">
                        <Testimonial />
                    </div>
                    <div className="w-full">
                        <h2 className="text-center text-3xl text-black font-bold tracking-widest my-4" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                            Top Trending Works
                        </h2>
                        <TrendingWorks />
                    </div>

                </div>

                <PaddFooter />

            </>
        )
    }
}

export default Landing